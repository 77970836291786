/*
 * @Author: ChenYaJin
 * @Date: 2023-09-07 10:01:23
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-08 16:09:38
 * @Description: 活动资讯
 */
import type { RouteLocationRaw } from '#vue-router'

/**资讯分类 */
export interface IInfoLevel {
  id?: string
  name?: string
  parentClassId?: string
  classLevel?: number
  sortValue?: number
  to?: string | RouteLocationRaw
  children?: IInfoLevel[]
}

/**资讯 */
export interface IInfo {
  id?: string
  /** 附件列表 */
  attachmentAddFormList?: {
    id: string
    name: string
  }[]
  name?: string
  backgroundImage?: string
  base64?: string
  content?: string
  type?: number // 类型 (0 图文；1 链接)
  jumpLink?: string // 跳转链接
  carousel?: number // 是否是轮播
  top?: number // 是否置顶
  pushTime?: string
  classId2?: string
}

/** 活动 */
export interface IActivity {
  id?: string
  name?: string
  type?: string
  startAt?: string
  entAt?: string
  status?: string
  participateNum?: number
  reviewerByNum?: number
  infoId?: string
}

export class Activity implements IActivity {
  id?: string
  name?: string
  type?: string
  startAt?: string
  entAt?: string
  status?: string
  participateNum?: number
  reviewerByNum?: number
  infoId?: string

  constructor(json: IActivity = {}) {
    this.id = json.id
    this.name = json.name
    this.type = json.type
    this.startAt = json.startAt
    this.entAt = json.entAt
    this.status = json.status
    this.participateNum = json.participateNum
    this.reviewerByNum = json.reviewerByNum
    this.infoId = json.infoId
  }
}

/** 活动评分 */
export interface IActivityScore {
  id?: string
  activityId?: string
  activityName?: string
  averageScore?: number
  status?: string
  commentId?: string
}

/** 活动评分详情 */
export interface IScoreDetail {
  rated?: number
  maxTotal?: number
  classs?: ScoreClass[]
}

/** 活动评语详情 */
export interface ICommentDetail {
  comment: string
}

export class ScoreDetail implements IScoreDetail {
  rated?: number
  maxTotal?: number
  classs?: ScoreClass[]

  constructor(json: IScoreDetail = {}) {
    this.rated = json.rated || 0
    this.maxTotal = json.maxTotal || 0
    this.classs = json.classs?.map((item) => new ScoreClass(item))
  }

  getScoreTotal() {
    const scores = this.classs?.map((item) => item.getScoreTotal())
    const total = scores?.reduce((a, b) => {
      return Number(a) + Number(b)
    }, 0)
    return (total || 0).toFixed(2)
  }
}

/** 评分-分类 */
export interface IScoreClass {
  id?: string
  name?: string
  maxTotal?: number
  standardList?: IScoreStandard[]
}

export class ScoreClass implements IScoreClass {
  id?: string
  name?: string
  maxTotal?: number
  standardList?: IScoreStandard[]

  constructor(json: IScoreClass = {}) {
    this.id = json.id
    this.name = json.name
    this.maxTotal = json.maxTotal
    this.standardList = json.standardList || []
  }

  getScoreTotal() {
    const scores = this.standardList?.map((item) => item.score)
    const total = scores?.reduce((a, b) => {
      return Number(a) + Number(b)
    }, 0)
    return (total || 0).toFixed(2)
  }
}

/** 评分标准 */
export interface IScoreStandard {
  id?: string
  name?: string
  content?: string
  maximumScore?: number
  must?: boolean
  comment?: string
}

// export class ScoreStandard implements IScoreStandard {
//   id?: string
//   name?: string
//   content?: string
//   maximumScore?: number
//   must?: boolean
//   score?: number

//   constructor(json: IScoreStandard = {}) {
//     this.id = json.id
//     this.name = json.name
//     this.content = json.content
//     this.maximumScore = json.maximumScore
//     this.must = json.must
//     this.score = json.score
//   }
// }

export enum DetailType {
  info = 'info',
  activity = 'activity',
}
